import "./Header.scss";
import LoginSignUpDialog from "../LoginSignUpDialog/LoginSignUpDialog";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChildLoginRestriction, setGuestSignupOpen, setLanguage, setLocation, setLocationName, setLoginSignUpOpen, setNotificationRefresh, setS3DataKey } from "../../Redux/Reducer/Reducer";
import EmailPhoneOtpLoginSignup from "../EmailPhoneOtpLoginSignup/EmailPhoneOtpLoginSignup";
import OtpVerificationDialog from "./../OtpVerificationDialog/OtpVerificationDialog";
import PersonalDetailsDialog from "../PersonalDetailsDialog/PersonalDetailsDialog";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  clearSessionStorage,
  getCurrentLocation,
  nullCheck,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  HOMEPAGE,
  ROUTES,
  SOCKET,
  areaArray,
  onInputChangeGoogleApi,
} from "../../Constants/Constants";
import AvatarSelectionDialog from "../AvatarSelectionDialog/AvatarSelectionDialog";
import PreferenceDialog from "../PreferenceDialog/PreferenceDialog";
import { Menu, MenuItem, TextField } from "@mui/material";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Dialog, DialogContent, DialogTitle, stackClasses } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

/**Images imports */
import learnAndPlay from "../../Assets/Global/LearnAndPlayLogo.svg";
import defaultProfile from "../../Assets/Authentication/defaultProfile.svg";
import applications from "../../Assets/Header/List.svg";
import calender from "../../Assets/Header/Calendar.svg";
import notification from "../../Assets/Header/notification.svg";
import favorites from "../../Assets/Header/Like.svg";
import translate from "../../Assets/Header/lang.svg";
import arrow from "../../Assets/Header/profile_arrow.svg";
import profileSettings from "../../Assets/Header/profileSettings.svg";
import aboutUs from "../../Assets/Header/aboutUs.svg";
import logout from "../../Assets/Header/logout.svg";
import backButton from "../../Assets/Header/switchArrow.svg";
import activeTick from "../../Assets/Header/activeTick.svg";
import add from "../../Assets/Header/add.svg";
import Autocomplete from "react-google-autocomplete";
import header_location_icon from "../../Assets/Header/header_location_icon.svg";
import Activities__gird from "../../Assets/Activities/activitiesIcon.svg";
import School_gird from "../../Assets/School/schoolIocn.svg";
import ThemePark__gird from "../../Assets/SideBar/themeParkActive.svg";
import Tutor__gird from "../../Assets/Tutor/TutorImg.svg";
import University__gird from "../../Assets/University/universityIcon.svg";
import EastIcon from '@mui/icons-material/East';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
// import required modules
import { Autoplay, Pagination, Mousewheel, Navigation, Keyboard, Grid } from 'swiper/modules';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ThemeParkGuestUserLogin from "../../Pages/ThemeParkDasboard/ThemeParkGuestUserLogin/ThemeParkGuestUserLogin";
import ThemeParkGuestUserLoginOtp from "../../Pages/ThemeParkDasboard/ThemeParkGuestUserLoginOtp/ThemeParkGuestUserLoginOtp";
import { useTranslation } from "react-i18next";
import LocationSelector from "./CountryRegLib";
import GTranslator from "./TransComponents/GTranslator";


declare global {
  interface Window {
    google: any;
    googleTranslateElementInit: any
  }
}

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  /**Initial states and update functions */
  const [openLoginSignUpDialog, setOpenLoginSignUpDialog] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [openGuestSignupDialog, setOpenGuestSignupDialog] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  const [
    openEmailPhoneOtpLoginSignupDialog,
    setOpenEmailPhoneOtpLoginSignupDialog,
  ] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  const [userName, setUserName] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isLogin, setIsLogin] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  const [isGoogle, setIsGoogle] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  const [googleSingUpData, setGoogleSingUpData] = useState<any>("");
  const [openOtpVerificationDialog, setOpenOtpVerificationDialog] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [openGuestOtpDialog, setOpenGuestOtpDialog] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [openPersonalDetailsDialog, setOpenPersonalDetailsDialog] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [openAvatarPickerDialog, setOpenAvatarPickerDialog] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [openPreferenceDialog, setOpenPreferenceDialog] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [profilePicture, setProfilePicture] = useState<any>("");
  const [hasAccessToken, setHasAccessToken] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [openProfile, setOpenProfile] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
  );
  const [parentProfileData, setParentProfileData] = useState<any>([]);
  const [childProfileData, setChildProfileData] = useState<any>([]);
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [GirdAnchorEl, setGirdAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [profileAccountType, setProfileAccountType] = useState<any>(
    AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
  );

  const [GridMenu, setGridMenu] = useState<any>(false);
  const [nearSearchList, setNearSearchList] = useState<any>([]);
  const [currentLat, setCurrentLat] = useState<any>('');
  const [currentLang, setCurrentLang] = useState<any>('');
  const [notifyRedDot, setNotifyRedDot] = useState<any>(false);
  const [SessionCheck, setSessionCheck] = useState<any>(false);
  const [ChildSelectOpen, setChildSelectOpen] = useState(false);
  const [SelectMapArea, setSelectMapArea] = useState<any>(1);
  const [hamberclick, setHamberClick] = useState(false);
  const [MenuCollapse, setMenuCollapse] = useState(false);
  const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
  function detectWindowSize() {
    window.innerWidth <= 992 ? setMenuCollapse(true) : setMenuCollapse(false);
  }

  window.onresize = detectWindowSize;


  const openProfileMenu: any = Boolean(profileAnchorEl);
  const openGridMenu: any = Boolean(GirdAnchorEl);

  useEffect(() => {
    updateUserDetails();
  }, [
    openOtpVerificationDialog,
    openPersonalDetailsDialog,
    openAvatarPickerDialog,
    openEmailPhoneOtpLoginSignupDialog,
    GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
  ]);


  /**Open login signup dialog */
  const loginSignUpOpenStatus: any = useSelector((state: any) => {
    return state.loginSignUpReducer.open;
  });

  /**Open login signup dialog */
  const guestSignupOpenStatus: any = useSelector((state: any) => {
    return state.guestSignupReducer.open;
  });

  /**Open Child login Restriction dialog */
  const childloginStatus: any = useSelector((state: any) => {
    return state.childLoginRestrictionReducer.open;
  });

  /**Update Login SignUp dialog open status from redux */
  useEffect(() => {
    setOpenLoginSignUpDialog(loginSignUpOpenStatus);
  }, [loginSignUpOpenStatus]);

  useEffect(() => {
    setOpenGuestSignupDialog(guestSignupOpenStatus);
  }, [guestSignupOpenStatus]);

  /**Update child Login  dialog open status from redux */
  useEffect(() => {
    setAuthenticationOpen(childloginStatus);
  }, [childloginStatus]);

  /**Open login signUp dialog */
  const handleOpenLoginSignUpDialog = () => {
    // dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    setOpenEmailPhoneOtpLoginSignupDialog(
      GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
    setIsLogin(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    setUserName("");
    setEmail("");
  };

  /**Close login signUp dialog */
  const handleOpenLoginSignUpDialogClose = (isLoginNavigation: any) => {
    dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullCheck(isLoginNavigation)) {
      setIsLogin(isLoginNavigation);
      setUserName('');
      setOpenEmailPhoneOtpLoginSignupDialog(
        GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
      );
    }
  };

  const handleGuestSignUpClose = (isSignupNavigation: any) => {
    if (nullUndefinedEmptyCheck(isSignupNavigation) && isSignupNavigation) {
      dispatch(setGuestSignupOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

    } else {
      dispatch(setGuestSignupOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    }
    // dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    // if (nullCheck(isLoginNavigation)) {
    //   setIsLogin(isLoginNavigation);
    //   setUserName('');
    //   setOpenEmailPhoneOtpLoginSignupDialog(
    //     GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    //   );
    // }
  };

  /**Close email phone otp login signup dialog */
  const handleCloseEmailPohneOtpLoginSignupDialog = () => {
    setOpenEmailPhoneOtpLoginSignupDialog(
      GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
    );
  };

  /**Back otp verification dialog */
  const handleBackOtpVerificationDialog = () => {
    setOpenOtpVerificationDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    setOpenEmailPhoneOtpLoginSignupDialog(
      GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
  };

  /**Back otp verification dialog */
  const handleBackGuestOtpDialog = () => {
    setOpenGuestOtpDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    // setGuestSignupOpen(
    //   GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    // );
    dispatch(setGuestSignupOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
  };


  /**Close otp verification dialog */
  const handleCloseOtpVerificationDialog = () => {
    setOpenOtpVerificationDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  };

  /**Close otp verification dialog */
  const handleCloseGuestOtpDialog = () => {
    setOpenGuestOtpDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  };

  /** close personal details dialog */
  const handlePersonalDetailsDialog = () => {
    setOpenPersonalDetailsDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  };

  /**close avatar picker dialog */
  const handleCloseAvatarPickerDialg = () => {
    setOpenAvatarPickerDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  };

  /**Close preference dialog */
  const handleClosePreferenceDialog = () => {
    setOpenPreferenceDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  };

  /**Open profile menu*/
  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (profileAccountType === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
      getProfileList();
    }
    setProfileAnchorEl(event.currentTarget);
  };

  /**Open Grid menu*/
  const handleOpenGirdMenu = (event: React.MouseEvent<HTMLElement>) => {
    setGirdAnchorEl(event.currentTarget);
  };

  /**Open Grid menu*/
  const handleNavigateMenu = (value: any) => {
    navigate(value);
    setGirdAnchorEl(null)
  };

  /**Get profile data */
  const getProfileList = () => {
    axiosInstance
      .get(`${API_END_POINTS.PROFILE_LIST}`)
      .then((profileListResponse: any) => {
        if (
          profileListResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setParentProfileData(profileListResponse.data.result.data.parent);
          setChildProfileData(profileListResponse.data.result.data.child);
        }
      })
      .catch(() => { });
  };

  /**Handle close profile menu */
  const handleClose = () => {
    setProfileAnchorEl(null);
    setGirdAnchorEl(null)
    setOpenProfile(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
  };

  /**Handle switch profile */
  const toggleSwitchProfile = () => {
    setOpenProfile(!openProfile);
  };

  /**Handle logout */
  const handleLogOut = () => {
    clearSessionStorage();
    setProfilePicture("");
    setHasAccessToken(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    navigate(ROUTES.HOMEPAGE);
    handleClose();
  };

  /**Navigate to accounts */
  const goToAccounts = () => {
    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, AUTHENTICATION.SHARED.MY_PORTAL);
    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
    navigate(ROUTES.TUTOR_HUB);
    handleClose();
  };

  /**Handle switch profile */
  const switchProfile = (
    accountType: any,
    childId: any,
    selectedEmail: any
  ) => {
    if (email === selectedEmail) {
      return;
    }
    let childPayload: any = {
      account_switch_type: accountType,
      child_id: childId,
    };
    let parentPayload: any = {
      account_switch_type: accountType,
      parent_id: childId,
    };
    axiosInstance
      .post(
        `${API_END_POINTS.PROFILE_SWITCH}`,
        accountType === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
          ? parentPayload
          : childPayload
      )
      .then((profileSwitchResponse: any) => {
        if (
          profileSwitchResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          handleClose();
          clearSessionStorage();
          toast.success(profileSwitchResponse.data.message);
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCOUNT_TYPE,
            accountType
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN,
            profileSwitchResponse.data.result.data.access_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.REFRESH_TOKEN,
            profileSwitchResponse.data.result.data.refresh_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.NAME,
            `${profileSwitchResponse.data.result.data.user_info.name}`
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.EMAIL,
            profileSwitchResponse.data.result.data.user_info.email
          );
          if (accountType === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.USER_ID,
              profileSwitchResponse.data.result.data.user_info.id
            );
          } else {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.USER_ID,
              profileSwitchResponse.data.result.data.user_info?.child
            );
          }
          if (
            nullUndefinedEmptyCheck(
              profileSwitchResponse.data.result.data.user_info.image
            )
          ) {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.PROFILE_PICTURE,
              profileSwitchResponse.data.result.data.user_info.image
            );
          }
          SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
          updateUserDetails();
          navigate(ROUTES.HOMEPAGE);
        }
      })
      .catch(() => { });
  };

  /**Update user details from session storage */
  const updateUserDetails = () => {
    if (
      nullUndefinedEmptyCheck(
        GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
      )
    ) {
      setProfilePicture(
        GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
      );
    }
    if (
      nullUndefinedEmptyCheck(
        GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN)
      )
    ) {
      setHasAccessToken(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    }
    if (
      nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.NAME))
    ) {
      setName(GetSessionStorageData(AUTHENTICATION.SHARED.NAME));
    }
    if (
      nullUndefinedEmptyCheck(
        GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL)
      )
    ) {
      setEmail(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL));
    }
    if (
      nullUndefinedEmptyCheck(
        GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE)
      )
    ) {
      setProfileAccountType(
        GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE)
      );
    }
  };


  /**Get Session Check */
  const getSessionCheck = () => {
    axiosInstance
      .get(`${API_END_POINTS.SESSION_CHECK}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setSessionCheck(Response?.data?.result?.data[0]?.booked_slots);
        }
      })
      .catch(() => { });
  };

  /**Get Session Check */
  const getS3DataKey = () => {
    axiosInstance
      .get(`${API_END_POINTS.S3_DATA_KEY}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          const decodedAccess_key = atob(Response?.data?.result?.data[0]?.value);
          const decodedSecret_key = atob(Response?.data?.result?.data[1]?.value);
          var data: any = {
            access_key: decodedAccess_key,
            secret_access_key: decodedSecret_key,
          }
          dispatch(setS3DataKey(data));
        }
      })
      .catch(() => { });
  };

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const handleChangeLanguage = (value: any) => {
    dispatch(setLanguage(value));
    i18n.changeLanguage(value);
    SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, value);
    // window.location.reload();
  }

  /**Header search location*/
  const HeaderLoctionName: any = useSelector((state: any) => {
    return state.locationNameReducer.locationName;
  });

  // useEffect(() => {
  //   SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, language);
  // }, [])

  useEffect(() => {
    // Get current user's location using the browser's Geolocation API
    // getCurrentLocation(successCallback, errorCallback);
    var location: any = {
      lat: 25.2048493,
      lng: 55.2707828,
    }
    // var locationName: any = 'Dubai - United Arab Emirates'
    var locationName: any = AUTHENTICATION.SHARED.UNITED_ARAB
    if (GetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME) && GetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION)) {
      var seesionLocationName: any = GetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME)
      var seesionLocation: any = GetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION)
      dispatch(setLocationName(seesionLocationName));
      dispatch(setLocation(JSON.parse(seesionLocation)));
    } else {
      dispatch(setLocation(location));
      dispatch(setLocationName(locationName));
      SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, locationName);
      SetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION, JSON.stringify(location))
    }
    // handleGetLocation();
  }, []);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Retrieve current location
          const { latitude, longitude } = position.coords;
          let currentLocation = { lat: latitude, lng: longitude };
          SetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION, JSON.stringify(currentLocation))
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };


  useEffect(() => {
    // getS3DataKey();
    const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
    if (nullUndefinedEmptyCheck(accessToken)) {
      getSessionCheck();
    }
  }, [GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN)])

  const handleChangeMapArea = (event: any) => {
    setSelectMapArea(event.target.value)
    var data: any = areaArray?.filter((item) => item?.id === event.target.value)
    var location: any = {
      lat: data[0]?.lat,
      lng: data[0]?.lng,
    }
    dispatch(setLocation(location));
  }

  const successCallback = (position: any) => {
    const { latitude, longitude } = position.coords;
    setCurrentLat(latitude);
    setCurrentLang(longitude)
    var location: any = {
      lat: latitude,
      lng: longitude,
    }
    dispatch(setLocation(location));
  };

  const errorCallback = (error: any) => {
    // console.log("errorCallback", error);
  };


  /**onInputChangeGoogle api Function*/
  const onInputChangeGoogle = async (value: any) => {
    if (nullUndefinedEmptyCheck(value)) {
      var response = await onInputChangeGoogleApi(value)
      if (nullUndefinedEmptyCheck(response?.data)) {
        setNearSearchList(response?.data?.results)
      } else {
        setNearSearchList([])
      }
    } else {
      getCurrentLocation(successCallback, errorCallback);
    }
  }

  /**Change the location api Function*/
  const NearPacleApi = (location: any) => {
    var locationData: any = {
      lat: location?.lat(),
      lng: location?.lng(),
    }
    dispatch(setLocation(locationData));
  }

  useEffect(() => {
    setNotifyRedDot(false);
    var user_id: any = GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID)
    const socket = new WebSocket(process.env.REACT_APP_NOTIFICATION_WEB_SOCKET + user_id + '/');

    /* Works on socket establishment */
    socket.onopen = (event: any) => {
      // console.log("establishment", event)
    };

    /* Listen to events for socket server */
    socket.onmessage = (event: any) => {
      if (event.data) {
        dispatch(
          setNotificationRefresh(
            JSON.parse(event.data)
          ));
      }
      setNotifyRedDot(true)
      // console.log("onmessage", event)
    };

    /* Closes socket on killing component */
    return () => {
      socket.close();
    }

    // socket.send(JSON.stringify({
    //   sender: user_id,
    //   receiver: 10,
    //   message: textMessage,
    //   room_id: 1
    // }))

  }, [])

  const handleAuthentication = () => {
    const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
    if (nullUndefinedEmptyCheck(accessToken)) {
      setChildSelectOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  }

  // const getCurrentTranslateLanguage = () => {
  //   const selectElement: any = document.querySelector(".goog-te-combo");
  //   if (selectElement) {
  //     return selectElement.value;
  //   }
  //   return null;
  // };

  // const handleTranslateLanguageChange = () => {
  //   const selectedLanguage = getCurrentTranslateLanguage();
  //   // Do something with the selected language
  //   if (selectedLanguage === 'ar') {
  //     SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'Arabic');
  //     dispatch(setLanguage('Arabic'));
  //     i18n.changeLanguage('Arabic');
  //   } else if (selectedLanguage === 'en') {
  //     SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'English');
  //     dispatch(setLanguage('English'));
  //     i18n.changeLanguage('English');
  //   }
  // };

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "ar",
  //       autoDisplay: true,
  //       includedLanguages: "en,ar",
  //       multilanguagePage: true,
  //     },
  //     "google_translate_element"
  //   );

  //   // Remove unnecessary elements
  //   const googleDivChild = document.querySelector("#google_translate_element .skiptranslate div");
  //   if (googleDivChild && googleDivChild.nextElementSibling) {
  //     googleDivChild.nextElementSibling.remove();
  //   }
  //   const googleDiv: any = document.getElementById("google_translate_element");
  //   googleDiv.childNodes?.forEach((node: any) => {
  //     if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== "") {
  //       googleDiv.removeChild(node);
  //     }
  //   });

  //   // Set up the language selection dropdown
  //   const selectElement: any = document.querySelector(".goog-te-combo");
  //   if (selectElement) {
  //     // Clear existing options
  //     selectElement.innerHTML = '';

  //     // Add English option
  //     const optionEn = document.createElement("option");
  //     optionEn.value = "en";
  //     optionEn.textContent = "English";
  //     selectElement.appendChild(optionEn);

  //     // Add Arabic option
  //     const optionAr = document.createElement("option");
  //     optionAr.value = "ar";
  //     optionAr.textContent = "Arabic";
  //     selectElement.appendChild(optionAr);

  //     // Set initial value to English after a brief timeout
  //     setTimeout(() => {
  //       if (language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH) {
  //         selectElement.value = "en";
  //       } else {
  //         selectElement.value = "ar";
  //       }
  //       // Dispatch change event manually as some browsers do not trigger it automatically
  //       const event = new Event("change", { bubbles: true });
  //       selectElement.dispatchEvent(event);
  //     }, 100);
  //     selectElement.addEventListener("change", handleTranslateLanguageChange);
  //   }
  // };

  // useEffect(() => {
  //   const addScript = document.createElement("script");
  //   addScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //   addScript.async = true;
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;

  //   // Hide the "Select Language" label using CSS
  //   const styleElement = document.createElement("style");
  //   styleElement.innerHTML = `
  //     .goog-te-combo option[value=""] {
  //       display: none;
  //     }
  //   `;
  //   document.head.appendChild(styleElement);

  //   return () => {
  //     document.body.removeChild(addScript);
  //     document.head.removeChild(styleElement);
  //   };
  // }, []);

  /*Below class name based on English Arabic*/
  const HeaderContainerLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "header_container_eng" : "header_container_arab";


  return (
    <>
      <div className={openLoginSignUpDialog || openEmailPhoneOtpLoginSignupDialog || openOtpVerificationDialog || openPersonalDetailsDialog
        || openAvatarPickerDialog || openPreferenceDialog || openGuestSignupDialog || openGuestOtpDialog ? "header_wrapper__blur" : "header_wrapper"}>
        <LoginSignUpDialog
          openLoginSignUpDialog={openLoginSignUpDialog}
          handleOpenLoginSignUpDialogClose={handleOpenLoginSignUpDialogClose}
        />
        <ThemeParkGuestUserLogin
          openGuestSignupDialog={openGuestSignupDialog}
          handleGuestSignUpClose={handleGuestSignUpClose}
          setOpenGuestOtpDialog={setOpenGuestOtpDialog}
          handleOpenLoginSignUpDialogClose={handleOpenLoginSignUpDialogClose}
          setOpenOtpVerificationDialog={setOpenOtpVerificationDialog}
          setIsEmail={setIsEmail}
          setUserName={setUserName}
          isLogin={isLogin}
          setIsLogin={setIsLogin}
        />
        <ThemeParkGuestUserLoginOtp
          openUserLoginOtp={openGuestOtpDialog}
          CloseUserLoginOtpModel={handleCloseGuestOtpDialog}
          handleBack={handleBackGuestOtpDialog}
          handleGuestSignUpClose={handleGuestSignUpClose}
        // isLogin={isLogin}
        // userName={userName}
        // isEmail={isEmail}
        // setOpenPersonalDetailsDialog={setOpenPersonalDetailsDialog}
        />
        <EmailPhoneOtpLoginSignup
          openEmailPhoneOtpLoginSignupDialog={
            openEmailPhoneOtpLoginSignupDialog
          }
          handleCloseEmailPohneOtpLoginSignupDialog={
            handleCloseEmailPohneOtpLoginSignupDialog
          }
          googleSingUpPass={setGoogleSingUpData}
          isLogin={isLogin}
          setIsLogin={setIsLogin}
          setOpenOtpVerificationDialog={setOpenOtpVerificationDialog}
          setUserName={setUserName}
          setIsEmail={setIsEmail}
          setIsGoogle={setIsGoogle}
          userName={userName}
          setOpenPersonalDetailsDialog={setOpenPersonalDetailsDialog}
        />
        <OtpVerificationDialog
          openOtpVerificationDialog={openOtpVerificationDialog}
          handleCloseOtpVerificationDialog={handleCloseOtpVerificationDialog}
          handleBack={handleBackOtpVerificationDialog}
          isLogin={isLogin}
          userName={userName}
          isEmail={isEmail}
          setOpenPersonalDetailsDialog={setOpenPersonalDetailsDialog}
        />

        <PersonalDetailsDialog
          openPersonalDetailsDialog={openPersonalDetailsDialog}
          handlePersonalDetailsDialog={handlePersonalDetailsDialog}
          setOpenEmailPhoneOtpLoginSignupDialog={
            setOpenEmailPhoneOtpLoginSignupDialog
          }
          googlepassData={googleSingUpData}
          userName={userName}
          isEmail={isEmail}
          isGoogle={isGoogle}
          setIsLogin={setIsLogin}
          setOpenAvatarPickerDialog={setOpenAvatarPickerDialog}
        />
        <AvatarSelectionDialog
          openAvatarPickerDialog={openAvatarPickerDialog}
          handleCloseAvatarPickerDialg={handleCloseAvatarPickerDialg}
          setOpenPreferenceDialog={setOpenPreferenceDialog}
        />
        <PreferenceDialog
          handleClosePreferenceDialog={handleClosePreferenceDialog}
          openPreferenceDialog={openPreferenceDialog}
        />
        <div className={`${HeaderContainerLanguage} header_container`}>
          <img src={learnAndPlay} alt="Learn and Play" className="logo__icon" onClick={() => {
            navigate(ROUTES.HOMEPAGE);
            window.location.reload();
          }} />
          <div className="d-flex flex-row gap-3">
            <div className="main_navigation_wrapper">
              {/* <div className="main_navigation_container">
                <div
                  className="main_navigation"
                  onClick={() => {
                    navigate(ROUTES.HOMEPAGE);
                  }}
                >
                  Home
                </div>
                {location.pathname.includes(ROUTES.HOMEPAGE || ROUTES.ACCOUNTS) && (
                  <div className="active_indicator home_indicator"></div>
                )}
              </div> */}
              <div className="main_navigation_container">
                <div
                  className={location.pathname.includes(ROUTES.HOMEPAGE) || location.pathname.includes(ROUTES.ACCOUNTS) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.HOMEPAGE);
                  }}
                >
                  {t("Home")}
                </div>
                {location.pathname.includes(ROUTES.HOMEPAGE) && (
                  <div className="active_indicator home_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div
                  className={location?.pathname?.includes(ROUTES.SCHOOLLAYOUT) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.SCHOOLLAYOUT);
                  }}
                >
                  {t(`${HOMEPAGE.SERVICE_TYPE.SCHOOL}`)}
                </div>
                {location.pathname.includes(ROUTES.SCHOOLLAYOUT) && (
                  <div className="active_indicator school_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div
                  className={location.pathname.includes(ROUTES.TUTORS) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.TUTORS);
                  }}
                >
                  {t(`${HOMEPAGE.SERVICE_TYPE.TUTOR}`)}
                </div>
                {location.pathname.includes(ROUTES.TUTORS) && (
                  <div className="active_indicator tutor_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div className={location.pathname.includes(ROUTES.ACTIVITYLAYOUT) ? "main_navigation" : "main_navigation__inactive"} onClick={() => { navigate(ROUTES.ACTIVITYLAYOUT); }}>{t(`${HOMEPAGE.SERVICE_TYPE.ACTIVITIES}`)}</div>
                {location.pathname.includes(ROUTES.ACTIVITYLAYOUT) && (
                  <div className="active_indicator activity_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div className={location.pathname.includes(ROUTES.THEMEPARK_LAYOUT) ? "main_navigation" : "main_navigation__inactive"} onClick={() => { navigate(ROUTES.THEMEPARK__HOMEPAGE) }}>{t(`${HOMEPAGE.SERVICE_TYPE.PARKS}`)}</div>
                {location.pathname.includes(ROUTES.THEMEPARK_LAYOUT) && (
                  <div className="active_indicator themePark_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div
                  className={location.pathname.includes(ROUTES.UNIVERSITYLAYOUT) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.UNIVERSITYLAYOUT);
                  }}
                >
                  {t(`${HOMEPAGE.SERVICE_TYPE.UNIVERSITY}`)}
                </div>
                {location.pathname.includes(ROUTES.UNIVERSITYLAYOUT) && (
                  <div className="active_indicator university_indicator"></div>
                )}
              </div>

            </div>
            <div className="navigation_icons_wrapper">
              <div className="search__autocomplete__header">
                {/* <Autocomplete
                  freeSolo
                  popupIcon
                  id="free-solo-2-demo"
                  disableClearable={false}
                  clearIcon={false}
                  value={HeaderLoctionName}
                  onChange={(e, value) => {
                    var Arr = nearSearchList?.filter((option: any) => option?.formatted_address === value);
                    NearPacleApi(Arr[0]);
                  }}
                  options={nearSearchList?.map((option: any) => option?.formatted_address)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Location"
                      onChange={(inputChangeEvent: any) => {
                        onInputChangeGoogle(inputChangeEvent.target.value.trim());
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: 'text',
                      }}
                    />
                  )}
                /> */}
                {/* <img src={header_location_icon} alt="" className="header_location_icon" />
                <Autocomplete
                  apiKey={nullUndefinedEmptyCheck(process.env.REACT_APP_GOOGLE_MAP_KEY) ? process.env.REACT_APP_GOOGLE_MAP_KEY : ""}
                  className="google__search__autocomplete"
                  types={['geocode']}
                  defaultValue={HeaderLoctionName}
                  onPlaceSelected={(place: any) => {
                    const location = place?.geometry?.location;
                    if (location) {
                      NearPacleApi(location)
                    } else {
                      getCurrentLocation(successCallback, errorCallback);
                    }
                  }}
                /> */}

                {/* <FormControl sx={{ m: 1, width: 180 }} size="small" className="header_location_dropdown">
                  <img src={header_location_icon} alt="" className="header_location_icon" />
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={t(`${SelectMapArea}`)}
                    label="Sort"
                    onChange={handleChangeMapArea}
                    sx={{ fontSize: 14, fontWeight: 400, marginLeft: 2 }}
                  >
                    {areaArray?.map((item: any) => {
                      return <MenuItem sx={{ fontSize: 14, fontWeight: 400, }} value={item.id}>{t(`${item?.locationName}`)}</MenuItem>
                    })}
                  </Select>
                </FormControl> */}

                {/* Country Drop Drown from CountryRegLib */}
                <LocationSelector />

              </div>
              <img src={applications} alt="Learn and Play" className={nullUndefinedEmptyCheck(GirdAnchorEl) ? "applications__icon" : ""}
                onClick={(event: any) => {
                  handleOpenGirdMenu(event);
                }} />
              <img src={calender} alt="Learn and Play" className={location.pathname.includes(ROUTES.CHILDCALENDER) ? "applications__icon" : ""}
                onClick={() => {
                  SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Calendar")
                  SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                  navigate(ROUTES.CHILDCALENDER)
                }} />
              {/* {SessionCheck ? (
                <img src={calender} alt="Learn and Play" className={location.pathname.includes(ROUTES.CHILDCALENDER) ? "applications__icon" : ""}
                  onClick={() => {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Calendar")
                    navigate(ROUTES.CHILDCALENDER)
                  }} />
              ) : (
                <img src={calender} alt="Learn and Play" onClick={handleAuthentication} />
              )} */}
              <div className="Pos__relative">
                {notifyRedDot && (<div className="notifybadeg__pos"></div>)}
                <img src={notification} alt="Learn and Play" className={location.pathname.includes(ROUTES.NOTIFICATION) ? "applications__icon" : ""}
                  onClick={() => {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Notifications")
                    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                    setNotifyRedDot(false);
                    navigate(ROUTES.NOTIFICATION)
                  }} />
              </div>
              <img src={favorites} alt="Learn and Play" className={location.pathname.includes(ROUTES.FAVOURTIES) ? "applications__icon" : ""}
                onClick={() => {
                  SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Favourites")
                  SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                  navigate(ROUTES.FAVOURTIES)
                }} />
              <div
                className="profile_arrow_wrapper"
                onClick={(event: any) => {
                  hasAccessToken
                    ? handleOpenAccountMenu(event)
                    : handleOpenLoginSignUpDialog();
                }}
              >
                <img
                  className="header__profile_picture"
                  src={
                    hasAccessToken && nullUndefinedEmptyCheck(profilePicture)
                      ? profilePicture
                      : defaultProfile
                  }
                  alt="Learn and Play"
                />
                {/* {hasAccessToken && <img src={arrow} alt="Learn and Play" />} */}
              </div>

              {/* {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                <img src={translate} alt="Learn and Play" className="icon__translate" onClick={() => handleChangeLanguage('Arabic')} />
              ) : (
                <img src={translate} alt="Learn and Play" className="icon__translate" onClick={() => handleChangeLanguage('English')} />
              )} */}


              <GTranslator />
              {/* <div id="google_translate_element" className="web_view" onChange={getCurrentTranslateLanguage}></div> */}



            </div>
          </div>
          {hamberclick ? (
            <CloseIcon className="Hamber__icon" onClick={() => setHamberClick(false)} />
          ) : (
            <MenuIcon className="Hamber__icon" onClick={() => setHamberClick(true)} />
          )}
        </div>
        {/* Hamber mobile view */}
        {hamberclick && MenuCollapse && (
          <div className="nav-options__Header">
            <div className="navigation_contant_title">
              <div className="main_navigation_container">
                <div
                  className={location.pathname.includes(ROUTES.HOMEPAGE) || location.pathname.includes(ROUTES.ACCOUNTS) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.HOMEPAGE);
                    setHamberClick(false);
                  }}
                >
                  {t('Home')}
                </div>
                {location.pathname.includes(ROUTES.HOMEPAGE) && (
                  <div className="active_indicator home_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div
                  className={location?.pathname?.includes(ROUTES.SCHOOLLAYOUT) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.SCHOOLLAYOUT);
                    setHamberClick(false);
                  }}
                >
                  {t(`${HOMEPAGE.SERVICE_TYPE.SCHOOL}`)}
                </div>
                {location.pathname.includes(ROUTES.SCHOOLLAYOUT) && (
                  <div className="active_indicator school_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div
                  className={location.pathname.includes(ROUTES.TUTORS) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.TUTORS);
                    setHamberClick(false);
                  }}
                >
                  {t(`${HOMEPAGE.SERVICE_TYPE.TUTOR}`)}
                </div>
                {location.pathname.includes(ROUTES.TUTORS) && (
                  <div className="active_indicator tutor_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div className={location.pathname.includes(ROUTES.ACTIVITYLAYOUT) ? "main_navigation" : "main_navigation__inactive"} onClick={() => { navigate(ROUTES.ACTIVITYLAYOUT); setHamberClick(false); }}>{t(`${HOMEPAGE.SERVICE_TYPE.ACTIVITIES}`)}</div>
                {location.pathname.includes(ROUTES.ACTIVITYLAYOUT) && (
                  <div className="active_indicator activity_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div className={location.pathname.includes(ROUTES.THEMEPARK_LAYOUT) ? "main_navigation" : "main_navigation__inactive"} onClick={() => { navigate(ROUTES.THEMEPARK__HOMEPAGE); setHamberClick(false); }}>{t(`${HOMEPAGE.SERVICE_TYPE.PARKS}`)}</div>
                {location.pathname.includes(ROUTES.THEMEPARK_LAYOUT) && (
                  <div className="active_indicator themePark_indicator"></div>
                )}
              </div>
              <div className="main_navigation_container">
                <div
                  className={location.pathname.includes(ROUTES.UNIVERSITYLAYOUT) ? "main_navigation" : "main_navigation__inactive"}
                  onClick={() => {
                    navigate(ROUTES.UNIVERSITYLAYOUT);
                    setHamberClick(false);
                  }}
                >
                  {t(`${HOMEPAGE.SERVICE_TYPE.UNIVERSITY}`)}
                </div>
                {location.pathname.includes(ROUTES.UNIVERSITYLAYOUT) && (
                  <div className="active_indicator university_indicator"></div>
                )}
              </div>
            </div>
            <div className=" d-flex flex-column">
              <div className="search__autocomplete__header__mobile">
                {/* <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={t(`${SelectMapArea}`)}
                    label="Sort"
                    onChange={(event: any) => {
                      handleChangeMapArea(event);
                      setHamberClick(false);
                    }}
                  >
                    {areaArray?.map((item: any) => {
                      return <MenuItem value={item.id}>{t(`${item?.locationName}`)}</MenuItem>
                    })}
                  </Select>
                </FormControl> */}

                {/* Country Drop Drown from CountryRegLib */}
                <LocationSelector />
              </div>
              <div className="navigation_icons_MobileView">
                <img src={applications} alt="Learn and Play" className={nullUndefinedEmptyCheck(GirdAnchorEl) ? "applications__icon" : ""}
                  onClick={(event: any) => {
                    handleOpenGirdMenu(event);
                    setHamberClick(false);
                  }} />
                <img src={calender} alt="Learn and Play" className={location.pathname.includes(ROUTES.CHILDCALENDER) ? "applications__icon" : ""}
                  onClick={() => {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Calendar")
                    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                    navigate(ROUTES.CHILDCALENDER)
                    setHamberClick(false);
                  }} />
                {/* {SessionCheck ? (
                  <img src={calender} alt="Learn and Play" className={location.pathname.includes(ROUTES.CHILDCALENDER) ? "applications__icon" : ""}
                    onClick={() => {
                      SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Calendar")
                      navigate(ROUTES.CHILDCALENDER)
                      setHamberClick(false);
                    }} />
                ) : (
                  <img src={calender} alt="Learn and Play" onClick={() => {
                    setHamberClick(false);
                    handleAuthentication();
                  }} />
                )} */}
                <div className="Pos__relative">
                  {notifyRedDot && (<div className="notifybadeg__pos"></div>)}
                  <img src={notification} alt="Learn and Play" className={location.pathname.includes(ROUTES.NOTIFICATION) ? "applications__icon" : ""}
                    onClick={() => {
                      SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Notifications")
                      SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                      setNotifyRedDot(false);
                      navigate(ROUTES.NOTIFICATION);
                      setHamberClick(false);
                    }} />
                </div>
                <img src={favorites} alt="Learn and Play" className={location.pathname.includes(ROUTES.FAVOURTIES) ? "applications__icon" : ""}
                  onClick={() => {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Favourites")
                    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                    navigate(ROUTES.FAVOURTIES);
                    setHamberClick(false);
                  }} />
                <div
                  className="profile_arrow_wrapper"
                  onClick={(event: any) => {
                    hasAccessToken
                      ? handleOpenAccountMenu(event)
                      : handleOpenLoginSignUpDialog();
                  }}
                >
                  <img
                    className="header__profile_picture"
                    src={
                      hasAccessToken && nullUndefinedEmptyCheck(profilePicture)
                        ? profilePicture
                        : defaultProfile
                    }
                    alt="Learn and Play"
                  />
                  {/* {hasAccessToken && <img src={arrow} alt="Learn and Play" />} */}
                </div>
                {/* {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                  <img src={translate} alt="Learn and Play" className="icon__translate" onClick={() => handleChangeLanguage('Arabic')} />
                ) : (
                  <img src={translate} alt="Learn and Play" className="icon__translate" onClick={() => handleChangeLanguage('English')} />
                )} */}




                {/* <div id="google_translate_element" className="mobile_view" onChange={getCurrentTranslateLanguage}></div> */}




              </div>
            </div>
          </div>
        )}


        <Menu
          anchorEl={GirdAnchorEl}
          open={openGridMenu}
          onClose={handleClose}>
          <div className="Header__gird_Menu__conatiner">
            <div className="module__wapper" onClick={() => handleNavigateMenu(ROUTES.SCHOOLLAYOUT)}>
              <img src={School_gird} alt="" />
              <div className="center__lable">
                <span>{t("School")}</span>
                <p>{t("Lorem Ipsum has been the industry's standard")}</p>
              </div>
              <EastIcon className="right__arrow" />
            </div>
            <div className="module__wapper" onClick={() => handleNavigateMenu(ROUTES.TUTORS)}>
              <img src={Tutor__gird} alt="" />
              <div className="center__lable">
                <span>{t("Tutor")}</span>
                <p>{t("Lorem Ipsum has been the industry's standard")}</p>
              </div>
              <EastIcon className="right__arrow" />
            </div>
            <div className="module__wapper" onClick={() => handleNavigateMenu(ROUTES.ACTIVITYLAYOUT)}>
              <img src={Activities__gird} alt="" />
              <div className="center__lable">
                <span>{t("Activities")}</span>
                <p>{t("Lorem Ipsum has been the industry's standard")}</p>
              </div>
              <EastIcon className="right__arrow" />
            </div>
            <div className="module__wapper" onClick={() => handleNavigateMenu(ROUTES.THEMEPARK__HOMEPAGE)}>
              <img src={ThemePark__gird} alt="" />
              <div className="center__lable">
                <span>{t("Parks and Events")}</span>
                <p>{t("Lorem Ipsum has been the industry's standard")}</p>
              </div>
              <EastIcon className="right__arrow" />
            </div>
            <div className="module__wapper" onClick={() => handleNavigateMenu(ROUTES.UNIVERSITYLAYOUT)}>
              <img src={University__gird} alt="" />
              <div className="center__lable">
                <span>{t("Universities")}</span>
                <p>{t("Lorem Ipsum has been the industry's standard")}</p>
              </div>
              <EastIcon className="right__arrow" />
            </div>

          </div>
        </Menu>

        <Menu
          anchorEl={profileAnchorEl}
          open={openProfileMenu}
          onClose={handleClose}
        >
          {openProfile && (
            <div>
              <div className="profile_switch_container">
                <div className="profile_name_switch">
                  <div className="profile_name_container">
                    <img
                      className="header__profile_picture__pop"
                      src={
                        hasAccessToken &&
                          nullUndefinedEmptyCheck(profilePicture)
                          ? profilePicture
                          : defaultProfile
                      }
                      onError={(e: any) => { e.target.src = defaultProfile; }}
                      alt="Learn and Play"
                    />

                    <div className="d-flex flex-column">
                      <div className="name_label">{name}</div>
                      {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                        <div className="role">{t("Parent")}</div>
                      ) : (
                        <div className="role">{t("Child")}</div>
                      )}
                    </div>
                  </div>
                  {/* {profileAccountType ===
                    AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                      <div
                        className="switch_profile_button"
                        onClick={toggleSwitchProfile}
                      >
                        Switch Profile
                      </div>
                    )} */}
                </div>
              </div>
              <MenuItem className="divider"></MenuItem>
              {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                <MenuItem>
                  <div className="Profile_select_item_conatiner">
                    <div className="accounts__wapper">
                      <div className="account_text">{t("Accounts")}</div>
                      {parentProfileData?.length + childProfileData?.length < 5 && (
                        <div className="accounts__wapper__add" onClick={() => {
                          SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, AUTHENTICATION.SHARED.PROFILE_ADD);
                          SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                          navigate(ROUTES.ADD_PROFILE)
                        }}>
                          <img src={add} alt="Learn and Play" className="add_icon" />
                          <div className="add_text" >{t("add")}</div>
                        </div>
                      )}
                    </div>
                    <div>
                      <Swiper
                        slidesPerView={2}
                        spaceBetween={30}
                        navigation={true}
                        dir={
                          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ltr" : "rtl"
                        }
                        key={language}
                        grid={{
                          rows: 1,
                        }}
                        keyboard={true}
                        // pagination={{
                        //   clickable: true,
                        // }}
                        modules={[Pagination, Keyboard, Grid, Navigation]}
                        className="mySwiper"
                      >
                        {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                          <>
                            {parentProfileData.map((parentElement: any, i: any) => {
                              return (
                                <>
                                  <SwiperSlide key={i}>
                                    <div className="profile__card__conatiner">
                                      <div className="contant__wapper">
                                        <img src={
                                          nullUndefinedEmptyCheck(
                                            parentElement.profile_image
                                          )
                                            ? parentElement.profile_image
                                            : defaultProfile
                                        } alt="" className="profile_Img" onError={(e: any) => { e.target.src = defaultProfile; }} />
                                        <div className="profile_Name">{parentElement.name}</div>
                                        <div className="role">Parent</div>
                                        {email === parentElement.email ? (
                                          <div className="primary_badge">{t("Primary")}</div>
                                        ) : (
                                          <div className="select_badge"
                                            onClick={() => {
                                              switchProfile(
                                                AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT,
                                                parentElement.id,
                                                parentElement.email
                                              );
                                            }}>{t("select")}</div>
                                        )}
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                </>
                              )
                            })}
                          </>
                        )}
                        {childProfileData.map((childElement: any, i: any) => {
                          return (
                            <>
                              <SwiperSlide key={i}>
                                <div className="profile__card__conatiner">
                                  <div className="contant__wapper">
                                    <img src={
                                      nullUndefinedEmptyCheck(
                                        childElement.profile_image
                                      )
                                        ? childElement.profile_image
                                        : defaultProfile
                                    } alt="" className="profile_Img" onError={(e: any) => { e.target.src = defaultProfile; }} />
                                    <div className="profile_Name">{childElement.name}</div>
                                    <div className="role">{t("Child")}{i + 1}</div>
                                    {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                                      <>
                                        {email === childElement.email ? (
                                          <div className="primary_badge">{t("primary")}</div>
                                        ) : (
                                          <div className="select_badge"
                                            onClick={() => {
                                              switchProfile(
                                                AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD,
                                                childElement.id,
                                                childElement.email
                                              );
                                            }}  >{t("select")}</div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            </>
                          )
                        })}
                      </Swiper>
                    </div>

                  </div>
                </MenuItem>
              )}
              <MenuItem onClick={goToAccounts}>
                <div className="account_contents_logo_container">
                  <img src={profileSettings} alt="Learn and Play" />
                  <div className="account_contents_lave">
                    {t("Profile & Settings")}
                  </div>
                </div>
              </MenuItem>
              <MenuItem onClick={() => {
                handleClose();
                navigate(ROUTES.ABOUTUS);
                SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "About Us");
                SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
              }}>
                <div className="account_contents_logo_container">
                  <img src={aboutUs} alt="Learn and Play" />
                  <div className="account_contents_lave">{t("About Us")}</div>
                </div>
              </MenuItem>
              <MenuItem className="divider"></MenuItem>
              <MenuItem onClick={handleLogOut}>
                <div className="logout_logo_wrapper">
                  <img src={logout} alt="Learn and Play" />
                  <div className="logout_labe">{t("Logout")}</div>
                </div>
              </MenuItem>
            </div>
          )}
          {!openProfile && (
            <div>
              <div className="back_button_wrapper">
                <img
                  onClick={toggleSwitchProfile}
                  src={backButton}
                  alt="Learn and Play"
                />
                <div className="switch_account_label">{t("Switch Account")}</div>
              </div>
              {parentProfileData.map((parentProfileElements: any) => {
                return (
                  <MenuItem
                    className="avatar_profile_button"
                    onClick={() => {
                      switchProfile(
                        AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT,
                        parentProfileElements.id,
                        parentProfileElements.email
                      );
                    }}
                  >
                    <div className="avatar_name_wrapper">
                      <div className="avatar_name_active_container">
                        <img
                          src={
                            nullUndefinedEmptyCheck(
                              parentProfileElements.profile_image
                            )
                              ? parentProfileElements.profile_image
                              : defaultProfile
                          }
                          alt="Learn and Play"
                          className="avatar_pic"
                        />
                        <div className="profile_name_role">
                          <div className="profile_name">
                            {parentProfileElements.name}
                          </div>
                          <div className="role">{t("Parent")}</div>
                        </div>
                      </div>
                      {email === parentProfileElements.email && (
                        <div className="active_icon_wrapper">
                          <img src={activeTick} alt="Learn and Play" />
                        </div>
                      )}
                    </div>
                  </MenuItem>
                );
              })}
              {childProfileData.map((childProfileElements: any) => {
                return (
                  <MenuItem
                    onClick={() => {
                      switchProfile(
                        AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD,
                        childProfileElements.id,
                        childProfileElements.email
                      );
                    }}
                  >
                    <div className="avatar_name_wrapper">
                      <div className="avatar_name_active_container">
                        <img
                          src={
                            nullUndefinedEmptyCheck(
                              childProfileElements.profile_image
                            )
                              ? childProfileElements.profile_image
                              : defaultProfile
                          }
                          alt="Learn and Play"
                          className="avatar_pic"
                        />
                        <div className="profile_name_role">
                          <div className="profile_name">
                            {childProfileElements?.name}
                          </div>
                          <div className="role">{t("Kid")}</div>
                        </div>
                      </div>
                      <div className="active_icon_wrapper"></div>
                    </div>
                  </MenuItem>
                );
              })}
              {parentProfileData?.length + childProfileData?.length < 5 && (
                <MenuItem onClick={() => {
                  SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, AUTHENTICATION.SHARED.PROFILE_ADD);
                  SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
                  navigate(ROUTES.ADD_PROFILE)
                }}>
                  <div className="add_profile_container">
                    <img src={add} alt="Learn and Play" className="add_icon" />
                    <div className="add_profile_label">{t("Add L&P Account")}</div>
                  </div>
                </MenuItem>
              )}
            </div>
          )}
        </Menu>
      </div>

      {ChildSelectOpen && (
        <div className="overlay-ModelBackround">
          <Dialog open={ChildSelectOpen}>
            <DialogContent>
              <div className='ChildSelect__container'>
                <div className='text__contant'>{t("Please Add to Book slot session")}</div>
                <div className="ChildSelectBtn__wapper__calendar">
                  <button className='BackBtn' onClick={() => setChildSelectOpen(false)}>{t("Back")}</button>
                  <button className='OkayBtn' onClick={() => setChildSelectOpen(false)}>{t("Okay")}</button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}

      {AuthenticationOpen && (
        <div className="overlay-ModelBackround">
          <Dialog open={AuthenticationOpen}>
            <DialogContent>
              <div className="ChildSelect__container">
                <div className="text__contant">
                  {t("Parent Required For Further Action!")}
                </div>
                <div className="ChildSelectBtn__wapper__Tutor">
                  <button
                    className="BackBtn"
                    onClick={() => dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE))}
                  >
                    {t("Back")}
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div >
      )}
    </>
  );
};

export default Header;
